import React, { useEffect, useState } from 'react';
import { FaCheck, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import { useModal } from 'src/hooks/Modal';
import Input from '../components/Forms/Input';
import MaskedInput from '../components/Forms/MaskedInput';

import SelectSimple from '../components/Forms/SelectSimple';
import { useLanguage } from "../hooks/Language"
import { countries } from "../pages/Products/modules/Login/countries";
import api from '../services/api';
import states from './SignUp/FormContainer/states';

interface IProps {
    changeCountry: Function;
    countryISO: string;
    checkUserValue?: Function;
    cart?: Record<string, any>[];
    key?: string;
    keyName?: string;
}

export const checkDocumentNumber = async (document_number) => {

    const doc = document_number?.toString().replace(/[^0-9]/g, '');
    const valid = await api.get('check-user-document_number', { params: { document_number: doc } });

    return valid?.data?.valid || 'no';

}


const DefaultSignUpFormV4: React.FC<IProps> = ({ key = 'product', keyName = 'product', checkUserValue = (value) => { }, changeCountry, cart }) => {

    const [countryISO, setCountryISO] = useState('BR')
    const [checkUser, setCheckUser] = useState('')
    const [currentDocumentNumber, setCurrentDocumentNumber] = useState('');
    const { translate } = useLanguage();

    const { addModal } = useModal();

    useEffect(() => {
        setCheckUser('');
        checkUserValue('');
    }, [keyName])
    const verifyAssociate = async (doc, product) => {



        try {

            const validate = await api.get(`/verify-association-api/${product?.hash_link?.toString()}/${product?.project_id}/${doc}`);

            if (validate.status === 200) {
                return true;
            }
            else {
                return false;
            }


        }
        catch (err) {

            addModal({ theme: 'whiteModalMini', title: '', content: <> <p style={{ color: '#333', padding: '10px', fontSize: '14px', textAlign: 'center', width: '100%' }}>{err?.response?.data?.message || err?.response?.data?.error || 'Produto exclusivo para associados'} </p></> })
            return false;
        }



    }


    return <>



        <SelectSimple index={3} setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />

        {countryISO === 'BR' ?
            <SelectSimple index={4} options={states} name="state" label={'Estado'} />
            : <Input name="state" placeholder="Estado" />}
        <Input name="city" placeholder="Município" />
        <Input type='text' name='name' placeholder='Nome completo' />
        <Input type='text' name='email' placeholder='E-mail' />
        {countryISO === 'BR' ? <MaskedInput
            mask="999.999.999-99"
            name="document_number"
            placeholder={translate('CPF')}
        /> : <Input name="document_number"
            placeholder={translate('Nº do documento')} />}

        <MaskedInput
            mask="99/99/9999"
            name="extra_5"
            placeholder="Data de nascimento"
        />


        {countryISO === 'BR' ? <MaskedInput mask='(99) 99999.9999' type='text' name='phone' placeholder='Celular / Whatsapp' />
            : <Input name="phone"
                placeholder={translate('Número do telefone')} />}

        <Input type='text' name='rg' placeholder='RG' />
        <Input type='text' name='company' placeholder='Instituição' />
        <Input type='text' name='position' placeholder='Cargo na instituição' />
        <SelectSimple index={5} options={states} name="extra_4" label={'Estado da instituição'} />
        <MaskedInput

            mask="99.999.999/9999-99"
            name="extra_1"
            placeholder={`${translate('CNPJ da instituição')}`}
        />

        <p style={{ color: '#333', padding: '20px 0px' }}>Se você vem de outro estado (que não o RS), por favor responda: </p>
        <SelectSimple index={6} options={[
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }


        ]} name="inscricao_1" label={'Já planejou o seu transporte entre o Aeroporto Salgado Filho (POA) e a cidade de Gramado?'} />


    </>
}

export default DefaultSignUpFormV4;