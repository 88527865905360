import React from 'react';
import { TableStyle } from "../../../styles"

const TableWidget = ({ data, computer, mobile }) => {

    return <TableStyle computer={computer} mobile={mobile}>


        <table>

            <tbody>
                {data?.map(row => <tr>{row?.map(cell => <td style={{ background: cell?.background || '', color: cell?.color || '#333', padding: cell?.padding || '15px', maxWidth: cell?.maxWidth || '100%' }} colSpan={cell?.colspan || 1} rowSpan={cell?.rowspan || 1}><p dangerouslySetInnerHTML={{ __html: cell?.text }} /></td>)} </tr>)}
            </tbody>
        </table>


    </TableStyle>


}

export default TableWidget;